var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "recent-book-video-section"
  }, [_c('home-section-header', {
    attrs: {
      "title": "ملخصات زادي",
      "color": "#9ED8D3"
    }
  }), _c('book-video', {
    attrs: {
      "book": _vm.book
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }