var render = function () {
  var _vm$book, _vm$book2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "book-details-page"
  }, [!_vm.isLoadingBook ? [_c('page-header', {
    attrs: {
      "title": (_vm$book = _vm.book) === null || _vm$book === void 0 ? void 0 : _vm$book.title,
      "route-name": "ZadiBooks"
    }
  }), _c('book-summary-details', {
    attrs: {
      "book": _vm.book
    }
  }), _vm.book && (_vm$book2 = _vm.book) !== null && _vm$book2 !== void 0 && _vm$book2.summeryVideoUrl ? _c('recent-book-video-section', {
    attrs: {
      "book": _vm.book
    }
  }) : _vm._e()] : [_c('cover-loader', {
    attrs: {
      "is-cover": ""
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }