<template>
  <div class="home-section-header">
    <p :style="headColor">{{ title }}</p>
    <p v-if="routeName" @click="$router.push({ name: routeName })">عرض الكل</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => null,
    },
    routeName: {
      type: String,
      default: () => null,
    },
    color: {
      type: String,
      default: () => '#f4acbf',
    },
  },
  computed: {
    headColor() {
      return { color: this.color }
    },
  },
};
</script>

<style scoped>
.home-section-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

p:nth-child(1) {
  font-size: 22px;
  font-weight: 700;
  color: #f4acbf;
}

p:nth-child(2) {
  font-size: 16px;
  font-weight: 700;
  color: #515667;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-bottom: 2px solid transparent;
}

p:nth-child(2):hover {
  border-bottom: 2px solid #515667;
}
</style>