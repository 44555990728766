var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home-section-header"
  }, [_c('p', {
    style: _vm.headColor
  }, [_vm._v(_vm._s(_vm.title))]), _vm.routeName ? _c('p', {
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: _vm.routeName
        });
      }
    }
  }, [_vm._v("عرض الكل")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }