<template>
  <div class="book-details-page">
    <template v-if="!isLoadingBook">
      <page-header :title="book?.title" route-name="ZadiBooks" />
      <book-summary-details :book="book" />
      <recent-book-video-section :book="book" v-if="book && book?.summeryVideoUrl"/>
    </template>
    <template v-else>
      <cover-loader is-cover />
    </template>
  </div>
</template>

<script>
import PageHeader from "../components/shared/PageHeader.vue";
import BookSummaryDetails from "../components/books/BookSummaryDetails.vue";
import RecentBookVideoSection from "../components/home/RecentBookVideoSection.vue";
import CoverLoader from "../components/shared/CoverLoader.vue";
import booksRepository from "../repositories/booksRepository";

export default {
  metaInfo: {
    title: "مشروع زادي | تفاصيل الكتاب ",
  },
  components: { PageHeader, BookSummaryDetails, RecentBookVideoSection, CoverLoader },
  data() {
    return {
      book: null,
      isLoadingBook: false,
    }
  },
  async mounted() {
    this.isLoadingBook = true;
    const bookId = this.$route.params.id
    const res = await booksRepository.getBookById(bookId);
    this.book = await res?.data;
    this.isLoadingBook = false;
  }
};
</script>

<style scoped>
.book-details-page {
  padding: 40px 40px 80px 40px;
}

@media (max-width: 560px) {
  .book-details-page {
    padding: 40px 20px 80px 20px;
  }
}
</style>
